import ListingModel from "@/store/shared/listing/models/listingModel";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchModel from "@/store/shared/search/models/searchModel";
import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrEmployeesItem } from "@/api/hr/types/apiHrEmployeesItem";
import { CalendarDate } from "@/types/calendar/calendarDate";
import HrAbsencesFilter from "@/store/hr/modules/absences/types/hrAbsencesFilter";
import { ApiHrDepartment } from "@/api/hr/types/apiHrDepartment";
import FormState from "@/store/shared/form/models/formState";
import { HrAbsence } from "@/types/hr/absence/hrAbsence";
import { HrCreateAbsenceRequest, HrCreateAbsenceRequestHelper } from "@/types/hr/absence/hrCreateAbsenceRequest";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";

export default class HrAbsencesState implements IPageState {
	constructor(
		public listing: ListingModel<HrAbsence>,
		public sorting: SortingModel<string>,
		public paging: PagingModel,
		public search: SearchModel,
		public filter: HrAbsencesFilter,
		public route: RouteState,
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public year: number = 0,
		public isDepartmentsLoading: boolean = false,
		public isEmployeesLoading: boolean = false,
		public isCalendarLoading: boolean = false,
		public departments: ApiHrDepartment[] = [],
		public employees: ApiHrEmployeesItem[] = [],
		public currentOpenedAbsenceId?: string,
		public calendarDates: CalendarDate[] = [],
		public newAbsence: HrCreateAbsenceRequest = HrCreateAbsenceRequestHelper.getEmpty(),
		public years: ApiHrVacationYear[] = [],
		public isYearsLoading: boolean = false,
	)
	{
	}
}
