import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/organizationStructure/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import AbortService from "@/services/abortService";
import { cloneDeep, uniqBy } from "lodash";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import OrganizationStructureState from "@/store/hr/modules/organizationStructure/types/organizationStructureState";
import { HrOrganizationStructureController } from "@/api/hr/organizationStructure";
import { HrStorageController } from "@/api/hr/storage";
import { blobToDataURL } from "@/utils/file";
import { mapOrganizationStructure } from "@/store/hr/helpers/mapOrganizationStructure";
import { HrOrganizationStructureItemEmployee } from "@/types/hr/organizationStructure/hrOrganizationStructureItemEmployee";

const abortService = new AbortService();
const hrOrganizationStructureController = new HrOrganizationStructureController(abortService);
const hrStorageController = new HrStorageController(abortService);

const baseMixin = (new BaseMixinBuilder(abortService)).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new OrganizationStructureState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<OrganizationStructureState, any>>{};

const actions = <ActionTree<OrganizationStructureState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, getters, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.fetch);
		
		const uniqEmployees = uniqBy(state.items.map(x => [...(x.employees ?? []), x.head]).flat(), "pictureFileId");
		const employeesWithAvatar = uniqEmployees.filter(x => !!x?.pictureFileId) as HrOrganizationStructureItemEmployee[];
		const tasks: Promise<void>[] = [];
		
		employeesWithAvatar.forEach(x => tasks.push(dispatch(actionTypes.fetchAvatar, { employeeId: x.id, id: x.pictureFileId })));
		
		await Promise.all(tasks);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.fetch]({ dispatch, commit, state }) {
		commit(mutationTypes.SET_IS_LOADING, true);
		
		try {
			let structure = await hrOrganizationStructureController.getOrganizationStructure();
			
			commit(mutationTypes.SET_ITEMS, mapOrganizationStructure(structure));
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LOADING, false);
		}
	},
	async [actionTypes.fetchAvatar]({ dispatch, commit, state }, { employeeId, id }) {
		try {
			let file = await hrStorageController.getFile(id);
			
			commit(mutationTypes.SET_EMPLOYEE_PHOTO, { employeeId, value: blobToDataURL(file) });
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		}
	}
};

const mutations = <MutationTree<OrganizationStructureState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_ITEMS](state, value) {
		state.items = cloneDeep(value);
	},
	[mutationTypes.SET_IS_LOADING](state, value) {
		state.isLoading = value;
	},
	[mutationTypes.SET_OPENED_SUBSTRUCTURE_PARENT_DEPARTMENT_ID](state, value) {
		state.openedSubstructureParentDepartmentId = value;
	},
	[mutationTypes.SET_EMPLOYEE_PHOTO](state, { employeeId, value }) {
		state.items.map(x => [...(x.employees ?? []), x.head]
			.filter(y => y!.id === employeeId))
			.flat()
			.filter(x => !!x)
			.forEach(x => x!.photo = value);
	}
};

export {
	namespace, state, getters, actions, mutations
};

const hrOrganizationStructureModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default hrOrganizationStructureModule;
